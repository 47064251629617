import { useTranslation } from "@pancakeswap/localization";
import BigNumber from "bignumber.js";
import { Button, Heading, Text, TooltipText, Balance, Skeleton, useTooltip, Flex } from "@pancakeswap/uikit";
import styled from "styled-components";

import { ActionContainer, ActionContent, ActionTitles } from "./styles";
import { FARMS_SMALL_AMOUNT_THRESHOLD } from "../../../constants";

const StyledButton = styled(Button)`
  font-family: Riffic Free;
  font-size: 16px;
  border-radius: 24px;
  padding: 12px 24px;
  height: fit-content;
  margin-top: 6px;
`

export interface HarvestActionProps {
  earnings: BigNumber;
  earningsBusd: number;
  displayBalance: string | JSX.Element;
  pendingTx: boolean;
  userDataReady: boolean;
  proxyCakeBalance?: number;
  handleHarvest: () => void;
}

const HarvestAction: React.FunctionComponent<React.PropsWithChildren<HarvestActionProps>> = ({
  earnings,
  earningsBusd,
  displayBalance,
  pendingTx,
  userDataReady,
  proxyCakeBalance,
  handleHarvest,
}) => {
  const { t } = useTranslation();

  const toolTipBalance = !userDataReady ? (
    <Skeleton width={60} />
  ) : earnings.isGreaterThan(FARMS_SMALL_AMOUNT_THRESHOLD) ? (
    earnings.toFixed(5, BigNumber.ROUND_DOWN)
  ) : (
    `<Flex 0.00001`
  );

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    `${toolTipBalance} ${t(
      `BBC has been harvested to the farm booster contract and will be automatically sent to your wallet upon the next harvest.`
    )}`,
    {
      placement: "bottom",
    }
  );

  return (
    <Flex flexDirection="column" alignItems="flex-start" alignSelf="flex-start">
      <Text color="textSubtle" fontSize="16px">
        {t('BBC Earned')}
      </Text>
      {proxyCakeBalance ? (
        <>
          <TooltipText ref={targetRef} decorationColor="secondary">
            <Heading color={earnings.eq(0) ? 'textDisabled' : 'text'}>{displayBalance}</Heading>
          </TooltipText>
          {tooltipVisible && tooltip}
        </>
      ) : (
        <Heading fontSize={20} fontFamily="Riffic Free" color={earnings.eq(0) ? 'textDisabled' : 'text'}>{displayBalance}</Heading>
      )}
      <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsBusd} unit=" USD" prefix="~" />
      {
        earnings.gt(0) &&
        <StyledButton disabled={pendingTx} onClick={handleHarvest}>
          {pendingTx ? t('Harvesting') : t('Harvest')}
        </StyledButton>
      }
    </Flex>
  )
  // <ActionContainer style={{ minHeight: 124.5 }}>
  //   <ActionTitles>
  //     <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
  //       BBC
  //     </Text>
  //     <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
  //       {t("Earned")}
  //     </Text>
  //   </ActionTitles>
  //   <ActionContent>
  //     <div>
  //       {proxyCakeBalance ? (
  //         <>
  //           <TooltipText ref={targetRef} decorationColor="secondary">
  //             <Heading>{displayBalance}</Heading>
  //           </TooltipText>
  //           {tooltipVisible && tooltip}
  //         </>
  //       ) : (
  //         <Heading>{displayBalance}</Heading>
  //       )}
  //       {earningsBusd > 0 && (
  //         <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsBusd} unit=" USD" prefix="~" />
  //       )}
  //     </div>
  //     <Button ml="4px" disabled={earnings.eq(0) || pendingTx || !userDataReady} onClick={handleHarvest}>
  //       {pendingTx ? t("Harvesting") : t("Harvest")}
  //     </Button>
  //   </ActionContent>
  // </ActionContainer>
};

export default HarvestAction;
