import { ScaleLinear } from "d3";
import { useMemo } from "react";
import { styled } from "styled-components";

const StyledLine = styled.line<{ color: string }>`
  opacity: 1;
  stroke-width: 2;
  stroke: ${({color, theme}) => theme.colors[color]};
  fill: none;
  stroke-dasharray: 4;
`;

const StyledPath = styled.path<{ color: string }>`
  fill: ${({color, theme}) => theme.colors[color]};
`

export const Line = ({
  value,
  xScale,
  innerHeight,
  color = "white",
}: {
  value: number;
  xScale: ScaleLinear<number, number>;
  innerHeight: number;
  color?: string;
}) =>
  useMemo(
    () => {
      const x = xScale(value)
      return <>
        <StyledLine x1={x} y1="0" x2={x} y2={innerHeight} color={color} />
        <StyledPath d={`M ${x} -4 L ${x-8} -12 H ${x+8} Z`} color={color} />
      </>
    },
    [value, xScale, innerHeight]
  );
