/*
 * Generates an SVG path for the east brush handle.
 * Apply `scale(-1, 1)` to generate west brush handle.
 *
 *    |```````\
 *    |  | |  |
 *    |______/
 *    |
 *    |
 *    |
 *    |
 *    |
 *
 * https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
 */
export const brushHandlePath = (height: number) =>
  [
    // handle
    // `M 0 0`, // move to origin
    // `v ${height + 4}`, // vertical line
    // "m 1 0", // move 1px to the right
    // `V 0`, // second vertical line
    `M 0 4`, // move to origin
    "q 0 -4 4 -4", // rounded corner

    // head
    "h 8", // horizontal line
    "q 4 0 4 4", // rounded corner
    "v 32", // vertical line
    "q 0 4 -4 4", // rounded corner
    "h -8", // horizontal line
    `v ${height - 36}`,
    "q -2 4 -4 0",
    `z`, // close path
  ].join(" ");

export const brushHandleAccentPath = () =>
  [
    "m 5 7", // move to first accent
    "v 24", // vertical line
    "M 0 0", // move to origin
    "m 9 7", // move to second accent
    "v 24", // vertical line
    "z",
  ].join(" ");

export const OffScreenHandle = ({
  color,
  size = 10,
  margin = 10,
}: {
  color: string;
  size?: number;
  margin?: number;
}) => (
  <polygon
    points={`0 0, ${size} ${size}, 0 ${size}`}
    transform={` translate(${size + margin}, ${margin}) rotate(45) `}
    fill={color}
    stroke={color}
    strokeWidth="4"
    strokeLinejoin="round"
  />
);
