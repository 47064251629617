import { useTranslation } from "@pancakeswap/localization";
import { Text, Button } from "@pancakeswap/uikit";
import styled from "styled-components";
import { StyledActionContainer, ActionContent, ActionTitles } from "./styles";

const StyledButton = styled(Button)`
  font-family: Riffic Free;
  font-size: 16px;
  border-radius: 24px;
  padding: 12px 24px;
  height: fit-content;
  margin-top: 6px;
`

export interface EnableStakeActionProps {
  pendingTx: boolean;
  handleApprove: () => void;
}

const EnableStakeAction: React.FunctionComponent<React.PropsWithChildren<EnableStakeActionProps>> = ({
  pendingTx,
  handleApprove,
}) => {
  const { t } = useTranslation();

  return (
    <StyledActionContainer>
      <ActionTitles>
        <Text color="textSubtle" fontSize="16px">
          {t("Enable Farm")}
        </Text>
      </ActionTitles>
      <ActionContent>
        <StyledButton width="100%" disabled={pendingTx} onClick={handleApprove} variant="secondary">
          {t("Enable")}
        </StyledButton>
      </ActionContent>
    </StyledActionContainer>
  );
};

export default EnableStakeAction;
