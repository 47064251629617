import { styled } from "styled-components";
import { ToggleProps, HandleProps, InputProps, ScaleKeys, scales, StyleToggleProps } from "./types";

const scaleKeyValues = {
  sm: {
    handleHeight: "16px",
    handleWidth: "16px",
    handleLeft: "4px",
    handleTop: "2px",
    checkedLeft: "calc(100% - 20px)",
    toggleHeight: "25px",
    toggleWidth: "40px",
  },
  md: {
    handleHeight: "24px",
    handleWidth: "24px",
    handleLeft: "4px",
    handleTop: "3px",
    checkedLeft: "calc(100% - 28px)",
    toggleHeight: "32px",
    toggleWidth: "56px",
  },
  lg: {
    handleHeight: "30px",
    handleWidth: "30px",
    handleLeft: "5px",
    handleTop: "4px",
    checkedLeft: "calc(100% - 35px)",
    toggleHeight: "40px",
    toggleWidth: "72px",
  },
};

const getScale =
  (property: ScaleKeys) =>
  ({ scale = scales.LG }: ToggleProps) => {
    return scaleKeyValues[scale][property];
  };

export const Handle = styled.div<HandleProps>`
  background-color: white; // Change to white
  border-radius: 50%;
  cursor: pointer;
  height: ${getScale("handleHeight")};
  left: ${getScale("handleLeft")};
  position: absolute;
  top: 50%; // Center vertically
  transform: translateY(-50%); // Center vertically
  transition: left 200ms ease-in;
  width: ${getScale("handleWidth")};
  z-index: 1;
`;

export const Input = styled.input<InputProps>`
  cursor: pointer;
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:checked + ${Handle} {
    left: ${getScale("checkedLeft")};
  }
  /* 
  &:focus + ${Handle} {
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }

  &:hover + ${Handle}:not(:disabled):not(:checked) {
    box-shadow: ${({ theme }) => theme.shadows.focus};
  } */
`;

const StyledToggle = styled.div<StyleToggleProps>`
  align-items: center;
  background-color: ${({ $checked, disabled }) =>
    $checked && !disabled ? 'rgba(69, 167, 98, 1)' : ({ theme, $defaultColor }) => theme.colors[$defaultColor]};
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  cursor: pointer;
  display: inline-flex;
  height: ${getScale("toggleHeight")};
  position: relative;
  transition: background-color 200ms;
  width: ${getScale("toggleWidth")};
  padding: 2px;
`;

export default StyledToggle;
