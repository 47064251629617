import { ReactNode } from "react";
import { styled } from "styled-components";
import getThemeValue from "@pancakeswap/uikit/util/getThemeValue";
import { CardHeader, Flex, FlexGap, Heading, Text, WarningIcon } from "@pancakeswap/uikit";

const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string }>`
  background: linear-gradient(90deg, #180110 0%, #160110 50%, #140110 100%);
  border-radius: ${({ theme }) => `${theme.radii.card} ${theme.radii.card} 0 0`};
  padding-bottom: 0;
`;

export const PoolCardHeader: React.FC<
  React.PropsWithChildren<{
    isFinished?: boolean;
    isStaking?: boolean;
  }>
> = ({ isFinished = false, isStaking = false, children }) => {
  const background = isStaking ? "gradientBubblegum" : "gradientCardHeader";

  return (
    <Wrapper isFinished={isFinished}>
      <Flex alignItems="center" justifyContent="space-between">
        {children}
      </Flex>
    </Wrapper>
  );
};

export const PoolCardHeaderTitle: React.FC<
  React.PropsWithChildren<{ isFinished?: boolean; title: ReactNode; subTitle: ReactNode; wrong?: boolean }>
> = ({ isFinished, title, subTitle, wrong }) => {
  return (
    <Flex flexDirection="column">
      <Heading color={isFinished ? "textDisabled" : "body"} scale="lg" fontFamily="Riffic Free">
        {title}
      </Heading>
      <FlexGap>
        {
          wrong &&
          <WarningIcon height={12} color="yellow" />
        }
        <Text fontSize="14px" color={isFinished ? "textDisabled" : "textSubtle"}>
          {subTitle}
        </Text>
      </FlexGap>      
    </Flex>
  );
};
