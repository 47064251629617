import { ReactElement } from "react";
import { useTranslation } from "@pancakeswap/localization";
import { styled } from "styled-components";
import {
  Flex,
  LinkExternal,
  ScanLink,
  Skeleton,
  Text,
  HelpIcon,
  useTooltip,
  FarmMultiplierInfo,
  Link,
} from "@pancakeswap/uikit";
import { ChainId } from "@pancakeswap/chains";

export interface ExpandableSectionProps {
  scanAddress?: { link: string; chainId?: number; icon?: ReactElement };
  infoAddress?: string;
  removed?: boolean;
  totalValueFormatted?: string;
  lpLabel: string;
  onAddLiquidity?: (() => void) | string;
  isCommunity?: boolean;
  auctionHostingEndDate?: string;
  alignLinksToRight?: boolean;
  totalValueLabel?: string;
  multiplier?: string;
  farmCakePerSecond?: string;
  totalMultipliers?: string;
}

const Wrapper = styled.div`
  background: #6464643d;
  margin: -18px;
  border-radius: 16px;
  margin-top: 16px;
  padding: 20px;
`;

const StyledLinkExternal = styled(LinkExternal)`
  font-size: 16px;
  color: ${({theme}) => theme.colors.primary};
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  color: ${({theme}) => theme.colors.primary};
`;

const StyledScanLink = styled(ScanLink)`
  border-radius: 24px;
  background: #B7ADD11A;
  box-shadow: 0px 1px 1px 0px #FFFFFF1A inset;
  text-decoration: none;
  font-size: 16px;
  width: 100%;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  &:hover {
    box-shadow: 0px 2px 2px 0px #0000001A;
  }
`;

const StyledText = styled(Text)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DetailsSection: React.FC<React.PropsWithChildren<ExpandableSectionProps>> = ({
  scanAddress,
  infoAddress,
  removed,
  totalValueLabel,
  totalValueFormatted,
  lpLabel,
  onAddLiquidity,
  isCommunity,
  auctionHostingEndDate,
  alignLinksToRight = false,
  multiplier,
  farmCakePerSecond,
  totalMultipliers,
}) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation();

  const multiplierTooltipContent = FarmMultiplierInfo({
    farmCakePerSecond: farmCakePerSecond ?? "-",
    totalMultipliers: totalMultipliers ?? "-",
  });

  const { targetRef, tooltip, tooltipVisible } = useTooltip(multiplierTooltipContent, {
    placement: "bottom",
  });

  return (
    <Wrapper>
      {isCommunity && auctionHostingEndDate && (
        <Flex justifyContent="space-between">
          <Text>{t("Auction Hosting Ends")}:</Text>
          <Text>
            {new Date(auctionHostingEndDate).toLocaleString(locale, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Text>
        </Flex>
      )}
      <Flex justifyContent="space-between">
        <Text color="textSubtle" fontSize={18}>{totalValueLabel || t("Total Liquidity")}:</Text>
        {totalValueFormatted ? <Text>{totalValueFormatted}</Text> : <Skeleton width={75} height={25} />}
      </Flex>
      <hr />
      {/* {!removed && (
        <Flex mb="2px" justifyContent={alignLinksToRight ? "flex-end" : "flex-start"}>
          {onAddLiquidity ? (
            typeof onAddLiquidity === "string" ? (
              <StyledLinkExternal href={onAddLiquidity}>{t("Add %symbol%", { symbol: lpLabel })}</StyledLinkExternal>
            ) : (
              <StyledText color="primary" onClick={onAddLiquidity}>
                {t("Add %symbol%", { symbol: lpLabel })}
              </StyledText>
            )
          ) : null}
        </Flex>
      )} */}
      <Flex mb="2px" justifyContent="space-between">
        {onAddLiquidity ? (
          typeof onAddLiquidity === "string" ? (
            <StyledLink href={onAddLiquidity}>{t("Get %symbol%", { symbol: lpLabel })}</StyledLink>
          ) : (
            <StyledText color="primary" fontSize={16} onClick={onAddLiquidity}>
              {t("Add %symbol%", { symbol: lpLabel })}
            </StyledText>
          )
        ) : null}
        <StyledLink href={infoAddress}>{t("See Pair Info")}</StyledLink>
      </Flex>
      {scanAddress && (
        <StyledScanLink
          icon={scanAddress.icon}
          useBscCoinFallback={
            scanAddress.chainId ? [ChainId.BSC, ChainId.BSC_TESTNET].includes(scanAddress.chainId) : false
          }
          href={scanAddress.link}
        >
          {t("View Contract")}
        </StyledScanLink>
      )}
    </Wrapper>
  );
};
