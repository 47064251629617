import { useState, useMemo, useEffect } from "react";
import { styled } from "styled-components";
import debounce from "lodash/debounce";
import { useTranslation } from "@pancakeswap/localization";
import { Input } from "../Input";
import { CloseIcon, SearchIcon } from "../Svg";
import { IconButton } from "../Button";

const StyledInput = styled(Input)`
  margin-left: auto;
  padding: 0;
`;

const InputWrapper = styled.div`
  border-radius: 24px;
  border: 1px solid #F1D1E329;
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  padding: 0 8px;
  ${({ theme }) => theme.mediaQueries.sm} {
    // display: block;
  }
`;

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  initialValue?: string;
}

const SearchInput: React.FC<React.PropsWithChildren<Props>> = ({
  onChange: onChangeCallback,
  placeholder = "Search",
  initialValue,
}) => {
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    debouncedOnChange(e);
  };
  useEffect(() => {
    if (initialValue) {
      setSearchText(initialValue);
    }
  }, [initialValue]);

  return (
    <InputWrapper>
      <SearchIcon color="textDisabled" />
      <StyledInput value={searchText} onChange={onChange} placeholder={t(placeholder)} />
      {
        searchText.trim().length > 0 &&
        <IconButton variant="text" scale="xs" style={{ position: "absolute", right: "8px" }} onClick={() => setSearchText("")}>
          <CloseIcon color="textDisabled" />
        </IconButton>
      }
    </InputWrapper>
  );
};

export default SearchInput;
