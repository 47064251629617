import { BigNumber } from "bignumber.js";
import { useMemo } from "react";
import { formatLpBalance, getBalanceNumber } from "@pancakeswap/utils/formatBalance";
import { Flex, Balance, Heading, RefreshIcon, Text, FlexGap } from "@pancakeswap/uikit";
import { useTranslation } from "@pancakeswap/localization";

interface StakedLPProps {
  stakedBalance: BigNumber;
  tokenSymbol: string;
  quoteTokenSymbol: string;
  lpTokenStakedAmount: BigNumber;
  lpTotalSupply: BigNumber;
  lpTokenPrice: BigNumber;
  tokenAmountTotal: BigNumber;
  quoteTokenAmountTotal: BigNumber;
  pendingFarmLength?: number;
  decimals: number;
  onClickLoadingIcon?: () => void;
}

const StakedLP: React.FunctionComponent<React.PropsWithChildren<StakedLPProps>> = ({
  stakedBalance,
  quoteTokenSymbol,
  tokenSymbol,
  lpTotalSupply,
  lpTokenStakedAmount,
  lpTokenPrice,
  tokenAmountTotal,
  quoteTokenAmountTotal,
  pendingFarmLength = 0,
  decimals,
  onClickLoadingIcon,
}) => {
  const {t} = useTranslation()
  // const displayBalance = useMemo(() => {
  //   return formatLpBalance(stakedBalance, decimals);
  // }, [stakedBalance, decimals]);
  
  const share = useMemo(() => {
    return stakedBalance.dividedBy(lpTotalSupply)
  }, [stakedBalance, lpTotalSupply])
  
  const shareStaked = useMemo(() => {
    return stakedBalance.multipliedBy(100).dividedBy(lpTokenStakedAmount)
  }, [stakedBalance, lpTokenStakedAmount])
  
  const stakedUSD = useMemo(() => {
    return getBalanceNumber(lpTokenPrice.times(stakedBalance), decimals)
  }, [stakedBalance, lpTokenPrice, decimals])
  
  const tokenAmount = useMemo(() => {
    return tokenAmountTotal.multipliedBy(share)
  }, [tokenAmountTotal, share])
  
  const quoteTokenAmount = useMemo(() => {
    return quoteTokenAmountTotal.multipliedBy(share)
  }, [quoteTokenAmountTotal, share])

  return (
    <Flex flexDirection="column" alignItems="flex-start" width="100%">
      <Text color="textSubtle" fontSize="16px">
        {t('YOUR SHARE IN POOL')}
      </Text>
      <Flex justifyContent="space-between" width="100%">
        <div>
          <Flex>
            <Heading color={stakedBalance.eq(0) ? "textDisabled" : "text"} fontFamily="Riffic Free" fontSize={20}>{stakedBalance.eq(0) ? "-" : `${shareStaked?.toFixed(2)}%`}</Heading>
            {pendingFarmLength > 0 && <RefreshIcon style={{ cursor: "pointer" }} spin onClick={onClickLoadingIcon} />}
          </Flex>
          {
            stakedBalance.eq(0)
            ? <Text color="textSubtle" fontSize={12}>0.00 USD</Text>
            : <Balance fontSize="12px" color="textSubtle" decimals={2} value={stakedUSD} unit=" USD" prefix="~" />
          }
        </div>
        <Flex flexDirection="column" alignItems="flex-end">
          <FlexGap gap="8px">
            <Text color={tokenAmount.eq(0) ? "textSubtle" : "white"} fontSize={16}>{tokenAmount.eq(0) ? '0' : tokenAmount.toFixed(5)}</Text>
            <Text color="textSubtle" fontSize={16}>{tokenSymbol}</Text>
          </FlexGap>
          <FlexGap gap="8px">
            <Text color={quoteTokenAmount.eq(0) ? "textSubtle" : "white"} fontSize={16}>{quoteTokenAmount.eq(0) ? '0' : quoteTokenAmount.toFixed(5)}</Text>
            <Text color="textSubtle" fontSize={16}>{quoteTokenSymbol}</Text>
          </FlexGap>
        </Flex>
      </Flex>
      {/* {stakedBalance.gt(0) && lpTokenPrice.gt(0) && (
        <>
          <Balance
            fontSize="12px"
            color="textSubtle"
            decimals={2}
            value={getBalanceNumber(lpTokenPrice.times(stakedBalance), decimals)}
            unit=" USD"
            prefix="~"
          />
          <Flex style={{ gap: "4px" }}>
            <Balance
              fontSize="12px"
              color="textSubtle"
              decimals={2}
              value={stakedBalance.div(lpTotalSupply).times(tokenAmountTotal).toNumber()}
              unit={` ${tokenSymbol}`}
            />
            <Balance
              fontSize="12px"
              color="textSubtle"
              decimals={2}
              value={stakedBalance.div(lpTotalSupply).times(quoteTokenAmountTotal).toNumber()}
              unit={` ${quoteTokenSymbol}`}
            />
          </Flex>
        </>
      )} */}
    </Flex>
  );
};

export default StakedLP;
