import { ReactNode } from "react";
import { useTranslation } from "@pancakeswap/localization";
import { Text, IconButton, MinusIcon, AddIcon, Button, FlexGap } from "@pancakeswap/uikit";
import styled from "styled-components";
import { StyledActionContainer, ActionContent, ActionTitles, IconButtonWrapper } from "./styles";

const StyledButton = styled(Button)`
  font-family: Riffic Free;
  font-size: 16px;
  border-radius: 24px;
  padding: 12px 24px;
  height: fit-content;
  margin-top: 6px;
`

export interface StakedActionComponentProps {
  isStaked: boolean;
  lpSymbol: string;
  children?: ReactNode;
  disabledMinusButton?: boolean;
  disabledPlusButton?: boolean;
  onPresentWithdraw: () => void;
  onPresentDeposit: () => void;
}

const StakedActionComponent: React.FunctionComponent<React.PropsWithChildren<StakedActionComponentProps>> = ({
  isStaked,
  children,
  disabledMinusButton,
  disabledPlusButton,
  onPresentWithdraw,
  onPresentDeposit,
}) => {
  const { t } = useTranslation();

  return (
    <StyledActionContainer>
      {/* <ActionTitles>
        <Text bold color="secondary" fontSize="12px" pr="4px">
          {lpSymbol}
        </Text>
        <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
          {t("Staked")}
        </Text>
      </ActionTitles> */}
      <ActionContent>
        {children}
        {
          !isStaked ? (
            <StyledButton variant="secondary" onClick={onPresentDeposit} style={{ width: "100%" }}>
              {t('Stake LP')}
            </StyledButton>
          ) : (
            <FlexGap gap="16px" justifyContent="space-between" width="100%">
              <StyledButton disabled={disabledMinusButton} variant="secondary" onClick={onPresentWithdraw} style={{ flex: 1 }}>
                {t("Pull out")}
              </StyledButton>
              <StyledButton onClick={onPresentDeposit} variant="secondary" disabled={disabledPlusButton} style={{ flex: 1 }}>
                {t("Insert")}
              </StyledButton>
            </FlexGap>
          )
        }
        {/* <IconButtonWrapper>
          <IconButton mr="6px" variant="secondary" disabled={disabledMinusButton} onClick={onPresentWithdraw}>
            <MinusIcon color="primary" width="14px" />
          </IconButton>
          <IconButton variant="secondary" disabled={disabledPlusButton} onClick={onPresentDeposit}>
            <AddIcon color="primary" width="14px" />
          </IconButton>
        </IconButtonWrapper> */}
      </ActionContent>
    </StyledActionContainer>
  );
};

export default StakedActionComponent;
